import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { DataGridPro, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { ModuleContext, PreferenceContext, StudentMonitoringContext } from "../App";
import { useSnackbar } from "notistack";
import { GET_DASHBOARD, GET_SITTINGS, GET_STUDENT_SITTINGS } from "../graphql/queries";
import { DELETE_SITTING } from "../graphql/mutations";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StudentSittings = ({ student_id }) => {
  const { preferences, setPreferences, defaults } = useContext(PreferenceContext);
  const { setStudentMonitoringData } = useContext(StudentMonitoringContext);
  const { moduleSettings, setModuleSettings } = useContext(ModuleContext);
  let sitPref = {...preferences.sittings};
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [sittings, setSittings] = useState(null);
  const [value, setValue] = useState(0);

  const [getDashboard] = useLazyQuery(GET_DASHBOARD);

  const [getStudentSittings, { sittingsLoading, sittingsError, sittingsData }] = useLazyQuery(GET_STUDENT_SITTINGS, {
    onCompleted: (sittings) => {
      setSittings(sittings.getStudentSittings);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    getStudentSittings({
      variables: {
        student_id
      }
    });
  }, []);

  function getIsCompleted(params) {
    return params.row.isCompleted ? "Y" : "";
  }

  const viewTestDetail = (params) => async () =>  {
    console.log(params);
    setStudentMonitoringData({
      id: student_id
    });

    const modules = await getDashboard({
      variables: {
        user_id: student_id,
        role: "Student"
      }
    });

    if (!modules) return enqueueSnackbar("Can't get student sitting data", { variant: "warning" });
    if (value === 0) {
      const placementModule = modules.data?.getDashboard?.placements?.find(p => p.id === params.row.moduleId);
      if (!placementModule) return enqueueSnackbar("Module could not be found", { variant: "warning" });

      setModuleSettings({
        ...moduleSettings,
        selectedModule: placementModule,
        moduleType: "Placement"
      });

      history.push("/placement-question");
    } else if (value === 1) {
      const trainingModule = modules.data?.getDashboard?.trainings?.find(t => t.id === params.row.moduleId);
      if (!trainingModule) return enqueueSnackbar("Module could not be found", { variant: "warning" });

      setModuleSettings({
        ...moduleSettings,
        selectedModule: trainingModule,
        moduleType: "Training"
      });

      history.push("/training-question");
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        flex: 1,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            color="primary"
            onClick={viewTestDetail(params)}
          />
        ],
      },
      {
        field: "id",
        headerName: "Sitting ID",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "name",
        headerName: "Name",
        flex: 2,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "isCompleted",
        headerName: "Is Completed",
        flex: 1,
        headerAlign: "center",
        align: "center",
        type: "boolean"
      },
      {
        field: "upTo",
        headerName: "Progress",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "createdAt",
        headerName: "Started At",
        flex: 1,
        headerAlign: "center",
        type: "dateTime",
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy HH:mm:ss");
          }
        },
        align: "center",
      },
      {
        field: "completedDate",
        headerName: "Completed At",
        flex: 1,
        headerAlign: "center",
        type: "dateTime",
        getApplyQuickFilterFn: undefined,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy HH:mm:ss");
          }
        },
        align: "center",
      }
    ],
    []
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Tests" />
          <Tab label="Training Modules" />
          <Tab label="Training Assessments" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {
          (sittings?.testSittings.length > 0 ? (
            <DataGridPro
              rows={sittings?.testSittings}
              columns={columns}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              autoHeight
              loading={sittingsLoading}
              pagination
              density="compact"
            />
          ):(
            <Typography>No Test Sittings Available</Typography>
          ))
        }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {
          (sittings?.trainingSittings.length > 0 ? (
            <DataGridPro
              rows={sittings?.trainingSittings}
              columns={columns}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              autoHeight
              loading={sittingsLoading}
              pagination
              density="compact"
            />
          ):(
            <Typography>No Training Sittings Available</Typography>
          ))
        }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      {
          (sittings?.assessmentSittings.length > 0 ? (
            <DataGridPro
              rows={sittings?.assessmentSittings}
              columns={columns}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              autoHeight
              loading={sittingsLoading}
              pagination
              density="compact"
            />
          ):(
            <Typography>No Assessment Sittings Available</Typography>
          ))
        }
      </CustomTabPanel>
      </Paper>
    </Container>
  );
};

export default StudentSittings;
