import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { DataGridPro, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { AuthenticatedContext, ModuleContext, PreferenceContext, StudentMonitoringContext } from "../App";
import { useSnackbar } from "notistack";
import { GET_DASHBOARD, GET_SITTINGS } from "../graphql/queries";
import { DELETE_SITTING } from "../graphql/mutations";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';

const Sittings = ({ moduleType, moduleId }) => {
  const { preferences, setPreferences, defaults } = useContext(PreferenceContext);
  const { setStudentMonitoringData } = useContext(StudentMonitoringContext);
  const { moduleSettings, setModuleSettings } = useContext(ModuleContext);
  const { userRole } = useContext(AuthenticatedContext);
  let sitPref = {...preferences.sittings};
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(sitPref.pageSize || 10);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const [getDashboard] = useLazyQuery(GET_DASHBOARD);

  const [getSittings, { sittingsLoading, sittingsError, sittingsData }] = useLazyQuery(GET_SITTINGS, {
    onCompleted: (sittings) => {
      if (sittings?.getSittings.sittings.length > 0) {
        setRows(sittings.getSittings.sittings);
      }
      setRowCount(sittings.getSittings.total);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [deleteSitting] = useMutation(DELETE_SITTING, {
    onCompleted: (sitting) => {
      if (sitting.deleteSitting === true) {
        enqueueSnackbar(
          `Sitting deleted`,
          {
            variant: "success",
          }
        );
        if (page === 0) {
          getSittings({
            variables: {
              module_id: moduleId,
              module_type: moduleType,
              skip: page * pageSize,
              take: pageSize,
              filters: sitPref.filters,
              sort: sitPref.sort,
            }
          });
        } else {
          setPage(0);
        }
      } else {
        enqueueSnackbar(`No sitting was found`,
          {
            variant: "error",
          }
        );
      }
    },
    fetchPolicy: 'network-only',
  });

  const viewTestDetail = useCallback((params) => async () =>  {
    setStudentMonitoringData({
      id: params.row.studentId
    });

    const modules = await getDashboard({
      variables: {
        user_id: params.row.studentId,
        role: "Student"
      }
    });

    if (!modules) return enqueueSnackbar("Can't get student sitting data", { variant: "warning" });
    if (moduleType === "Placement") {
      const placementModule = modules.data?.getDashboard?.placements?.find(p => p.id === moduleId);
      if (!placementModule) return enqueueSnackbar("Module could not be found", { variant: "warning" });

      setModuleSettings({
        ...moduleSettings,
        selectedModule: placementModule,
        moduleType
      });

      history.push("/placement-question");
    } else if (moduleType === "Exercise") {
      const trainingModule = modules.data?.getDashboard?.trainings?.find(t => t.id === moduleId);
      if (!trainingModule) return enqueueSnackbar("Module could not be found", { variant: "warning" });

      setModuleSettings({
        ...moduleSettings,
        selectedModule: trainingModule,
        moduleType: "Training"
      });

      history.push("/training-question");
    }
    // if (studentMonitoringData && params) {
    //   const respond = await getDashboard({
    //     variables: {
    //       user_id: studentMonitoringData?.id,
    //       role: "Student",
    //     },
    //   });
    //   if (!respond) return enqueueSnackbar("Can not get student sitting data", { variant: "warning" })

    //   if (params.row.type === "Training") {
    //     const findTrainingModule = respond?.data?.getDashboard?.trainings?.find(i => i.id === params?.row?.moduleID)
    //     if (!findTrainingModule) return enqueueSnackbar("no module found", { variant: "warning" })

    //     setModuleSettings({
    //       ...moduleSettings,
    //       selectedModule: findTrainingModule,
    //       moduleType: "Training",
    //     });

    //     history.push("/training-question");
    //   } else {
    //     const findAssessmentModule = respond?.data?.getDashboard?.assessments?.find(i => i.id === params?.row?.moduleID)
    //     if (!findAssessmentModule) return enqueueSnackbar("no module found", { variant: "warning" })

    //     setModuleSettings({
    //       ...moduleSettings,
    //       selectedModule: findAssessmentModule,
    //       moduleType: "Assessment",
    //     });

    //     history.push("/assessment-question");
    //   }
    // }
  }, []);

  useEffect(() => {
    getSittings({
      variables: {
        module_id: moduleId,
        module_type: moduleType,
        skip: page * pageSize,
        take: pageSize,
        filters: sitPref?.filters || defaults.sittings.filters,
        sort: sitPref?.sort || defaults.sittings.sort,
      }
    });
  }, [page]);

  const handlePageSizeChange = (newPageSize) => {
    sitPref.pageSize = newPageSize;
    setPageSize(newPageSize);
    setPreferences({
      ...preferences,
      sittings: {
        ...sitPref
      }
    });
    if (page === 0) {
      getSittings({
        variables: {
          module_id: moduleId,
          module_type: moduleType,
          skip: page * newPageSize,
          take: newPageSize,
          filters: sitPref.filters,
          sort: sitPref.sort,
        }
      });
    } else {
      setPage(0);
    }
  };

  const handleColumnWidthChange = (params) => {
    sitPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      sittings: {
        ...sitPref
      }
    });
  };

  const handleColumnOrderChange = (params) => {
    sitPref.columns.splice(params.oldIndex, 1);
    sitPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      sittings: {
        ...sitPref
      }
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    sitPref.visible = {...model};
    setPreferences({
      ...preferences,
      sittings: {
        ...sitPref
      }
    });
  }

  const handleSortModelChange = (model) => {
    sitPref.sort = [...model];

    setPreferences({
      ...preferences,
      sittings: {
        ...sitPref
      }
    });
    if (page === 0) {
      getSittings({
        variables: {
          module_id: moduleId,
          module_type: moduleType,
          skip: page * pageSize,
          take: pageSize,
          filters: sitPref.filters,
          sort: [...model],
        }
      });
    } else {
      setPage(0);
    }
  };

  const handleFilterModelChange = (model) => {
    sitPref.filters = {...model};

    
    setPreferences({
      ...preferences,
      sittings: {
        ...sitPref
      }
    });
    if (page === 0) {
      getSittings({
        variables: {
          module_id: moduleId,
          module_type: moduleType,
          skip: page * pageSize,
          take: pageSize,
          filters: {...model},
          sort: sitPref.sort,
        }
      });
    } else {
      setPage(0);
    }
  };

  const viewStudent = useCallback(
    (params) => () =>
      history.push(`/registrations/${params.row.id}`, { data: params.row }),
    []
  );

  function getFullName(params) {
    return `${params.row.givenname || ''} ${params.row.surname || ''}`;
  }

  function getIsCompleted(params) {
    return params.row.isCompleted;
  }

  function getProgress(params) {
    return `${params.row.upTo}/${params.row.totalQuestions}`;
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const deleteSittingStart = () => {
    deleteSitting({variables: { module_id: moduleId, module_type: moduleType, student_id: selectedRow.studentId }});
    setDialog(false);
  }

  const startDelete = (params) => {
    setSelectedRow(params.row);
    setDialog(true);
  }

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: sitPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            color="primary"
            onClick={viewTestDetail(params)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="View"
            color="primary"
            onClick={() => startDelete(params)}
            disabled={userRole === "Teacher"}
          />,
        ],
      },
      {
        field: "id",
        headerName: "Sitting ID",
        width: sitPref?.widths?.id,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.id || !["Admin", "Education Consultant"].includes(userRole),
        getApplyQuickFilterFn: undefined,
        type: "number"
      },
      {
        field: "studentId",
        headerName: "Student ID",
        width: sitPref?.widths?.studentId,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.studentId || !["Admin", "Education Consultant"].includes(userRole),
        getApplyQuickFilterFn: undefined,
        type: "number"
      },
      {
        field: "studentNumber",
        headerName: "Student Number",
        width: sitPref?.widths?.studentNumber,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.studentNumber,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "name",
        headerName: "Name",
        width: sitPref?.widths?.name,
        headerAlign: "center",
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.name,
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getFullName,
      },
      {
        field: "campus",
        headerName: "Campus",
        width: sitPref?.widths?.campus,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.campus,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "isCompleted",
        headerName: "Is Completed",
        width: sitPref?.widths?.isCompleted,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.isCompleted,
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsCompleted,
        type: "boolean"
      },
      {
        field: "upTo",
        headerName: "Progress",
        width: sitPref?.widths?.upTo,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.upTo,
        getApplyQuickFilterFn: undefined,
        valueGetter: getProgress,
      },
      {
        field: "createdAt",
        headerName: "Started At",
        width: sitPref?.widths?.createdAt,
        headerAlign: "center",
        hide: !sitPref?.visible?.createdAt,
        type: "dateTime",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy HH:mm:ss");
          }
        },
        align: "center",
        filterable: false,
      },
      {
        field: "completedDate",
        headerName: "Completed At",
        width: sitPref?.widths?.completedDate,
        headerAlign: "center",
        hide: !sitPref?.visible?.completedDate,
        type: "dateTime",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy HH:mm:ss");
          }
        },
        align: "center",
        filterable: false,
      }
    ],
    [startDelete]
  );
  columns.sort((a, b) => sitPref.columns?.indexOf(a.field) - sitPref.columns?.indexOf(b.field));

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Dialog open={dialog}>
          <DialogTitle>Delete Sitting</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this sitting?
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={() => setDialog(false)}>Cancel</Button>
            <Button onClick={deleteSittingStart}>Delete</Button>
          </DialogActions>
      </Dialog>
      <Paper elevation={0} sx={{ p: 3 }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true
            }
          }}
          pageSize={sitPref.pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={sittingsLoading}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          pagination
          pinnedColumns={{left: ["actions"]}}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          filterModel={sitPref.filters}
          sortModel={sitPref.sort}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          rowCount={rowCount}
          density="compact"
        />
      </Paper>
    </Container>
  );
};

export default Sittings;
